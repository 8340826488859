"use strict";
exports.__esModule = true;
exports.UserKeys = void 0;
var UserKeys = /** @class */ (function () {
    function UserKeys(id, uid, email, isPrimarySib, // default = undefined; true if exists a duplicate, but this profile is selected as primary. false if duplicate = do not sync this account
    created, createdAccount, lang, firstname, firstname_norm, lastname, lastname_norm, degree, dob, nationality, gender, club, address, phone, news, avatar, passport, isPrintReceiver, cmia, cmitr, cmid, cmclu, cmcchp, gifts, logs, racerTrophies, championTrophies, specialTrophies) {
        this.id = id;
        this.uid = uid;
        this.email = email;
        this.isPrimarySib = isPrimarySib;
        this.created = created;
        this.createdAccount = createdAccount;
        this.lang = lang;
        this.firstname = firstname;
        this.firstname_norm = firstname_norm;
        this.lastname = lastname;
        this.lastname_norm = lastname_norm;
        this.degree = degree;
        this.dob = dob;
        this.nationality = nationality;
        this.gender = gender;
        this.club = club;
        this.address = address;
        this.phone = phone;
        this.news = news;
        this.avatar = avatar;
        this.passport = passport;
        this.isPrintReceiver = isPrintReceiver;
        this.cmia = cmia;
        this.cmitr = cmitr;
        this.cmid = cmid;
        this.cmclu = cmclu;
        this.cmcchp = cmcchp;
        this.gifts = gifts;
        this.logs = logs;
        this.racerTrophies = racerTrophies;
        this.championTrophies = championTrophies;
        this.specialTrophies = specialTrophies;
    }
    return UserKeys;
}());
exports.UserKeys = UserKeys;

import React, {ReactElement} from 'react';
import {T} from '@tolgee/react';

export default function NoMoreTile(): ReactElement {
	return (
		<div className="DashboardTile NoMoreTile">
			<div className='DashboardTile__Frame'>
				<h4></h4>

				<div className="description">Visit <a href='https://passport.euroloppet.com'>https://passport.euroloppet.com</a> for purchase.</div>
			</div>
		</div>
	)
}

import BuyTile from 'components/Passport/BuyTile';
import IUser from 'definitions/IUser';
import React, {Dispatch} from 'react';
import {T,  useTranslate} from '@tolgee/react';
import NoMoreTile from '../NoMoreTile';

interface Props {
	dbUser: IUser,
	dispatch: Dispatch<any>,
}

export default function PassportNone(props: Props) {
	const t = useTranslate();

	return (
		<div className="PassportNone">
			<div className="PassportNone__Subtitle"><T>You have no Euroloppet pass connected to your account.</T></div>
			{false && <BuyTile title={t('Buy an Euroloppet passport')} dispatch={props.dispatch} />}
			<NoMoreTile />
		</div>
	)
}

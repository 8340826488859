import BuyTile from 'components/Passport/BuyTile';
import PassportDetail from 'components/Passport/PassportDetail';
import PassportPdfDownload from 'components/Passport/PassportPdfDownload';
import {IPassport} from 'myeuroloppet-shared';
import IUser from 'definitions/IUser';
import React, {Dispatch, useState} from 'react';
import {T, useTranslate} from '@tolgee/react';
import NoMoreTile from '../NoMoreTile';

interface Props {
	dbUser: IUser,
	dispatch: Dispatch<any>,
}

export default function Passport(props: Props) {
	const pass = props.dbUser.passport as IPassport;
	const [historyOpen, setHistoryOpen] = useState(false);
	const [isDetailOpen, setIsDetailOpen] = useState(false);
	const t = useTranslate();
	
	const expirationLocal = pass ? new Date(pass?.validTo).toLocaleDateString() : '';
	const isValid = pass && new Date(pass.validTo) >= new Date();

	return (
		<div className="Passport">
			<div className="Passport__Properties">
				<div className="Passport__Properties__Values">
					<div className='main'>
						<div className="label"><T>Number</T>:</div>
						<div className="number">{pass?.id}</div>
					</div>
					
					<div className={`expiration ${isValid ? 'active' : 'expired'}`}>
						<div className='label'><T>Active to</T>:</div>
						<div className="date">{expirationLocal}</div>
					</div>
				</div>
				<div className='Passport__Properties__Image'>
					<img src='passport.png' alt='passport' onClick={() => setIsDetailOpen(true)} />
				</div>
				{pass.itemsIds.filter((id) => id !== pass.id).length > 0 &&
					<div className={`Passport__Properties__History ${historyOpen ? 'Passport__Properties__History--open' : ''}`}>
						<div className='Passport__Properties__History__Trigger' onClick={() => setHistoryOpen(!historyOpen)}>
							<span className='Passport__Properties__History__Trigger--mobile'>&#x25BC;</span>
							<T>Other passports</T>
							<span className='Passport__Properties__History__Trigger--desktop'>▶</span>
						</div>
						<div className='Passport__Properties__History__Content'>
							{pass.itemsIds.filter((id) => id !== pass.id).map((pId, i) => <div key={i}>{pId}</div>)}
						</div>
					</div>
				}
				<div className='Passport__Properties__Download'>
					<PassportPdfDownload user={props.dbUser} />
				</div>
			</div>

			{false && <BuyTile title={t('Validity period expired?')} dispatch={props.dispatch} />}
			<NoMoreTile />

			{isDetailOpen && <PassportDetail user={props.dbUser} passport={pass} onClose={() => setIsDetailOpen(false)} />}
		</div>
	)
}
